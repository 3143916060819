import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem, Input, Card, CardHeader, CardContent } from './CustomUIComponents.jsx';

// Import your genres data
import genresData from '../genres.json';

const GenreListener = () => {
  const [genres, setGenres] = useState(genresData);
  const [selectedGenre, setSelectedGenre] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [listenerCount, setListenerCount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedGenre) {
      fetchListenerCount(selectedGenre);
    }
  }, [selectedGenre]);

  const fetchListenerCount = async (genre) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://cruncher.asset.money/artist/listener-count?genre=${encodeURIComponent(genre)}`);
      setListenerCount(response.data.data);
    } catch (error) {
      console.error('Error fetching listener count:', error);
      setListenerCount(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenreChange = (value) => {
    setSelectedGenre(value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredGenres = genres.filter(genreObj => 
    genreObj.genre.toLowerCase().includes(searchTerm)
  );

  return (
    <div className="p-4 max-w-md mx-auto ">
      <h1 className="text-2xl font-bold mb-4">User Listener Dashboard</h1>

      <Select value={selectedGenre} onValueChange={handleGenreChange} className="z-50">
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select a genre" />
        </SelectTrigger>
        <SelectContent className="z-50">
          {filteredGenres.map((genreObj) => (
            <SelectItem key={genreObj.genre} value={genreObj.genre}>
              {genreObj.genre}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      {selectedGenre && (
        <Card className="mt-4 z-10">
          <CardHeader>USERS who listen to {selectedGenre}</CardHeader>
          <CardContent>
            {isLoading ? (
              <p>Loading...</p>
            ) : listenerCount !== null ? (
              <p className="text-4xl font-bold">{listenerCount.toLocaleString()}</p>
            ) : (
              <p>Error fetching data</p>
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default GenreListener;
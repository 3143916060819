import React, { useEffect, useState } from 'react';

const TotalCumulativeUsersMonthly = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('https://cruncher.asset.money/artist/user-count-for-this-month')
            .then(response => response.json())
            .then(data => {
                setData(data.data);
                setIsLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoading(false);
            });
    }, []);


    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="card">
            <h2 className="text-2xl font-bold mb-4">Total Cumulative Users This Month</h2>
            {isLoading ? (
                <div className="number loading">Loading...</div>
            ) : error ? (
                <div className="number error">Error: {error}</div>
            ) : (
                <div className="number">{data.toLocaleString()}</div>
            )}
        </div>
    );
};

export default TotalCumulativeUsersMonthly;
import React, { useState, useEffect } from 'react';
import './App.css';
import GenreListener from './Components/GenreListener';
import TotalCumulativeUsersMonthly from './Components/TotalCumulativeUsersMonthly';
import WeekOnWeekTable from './Components/WeekOnWeekTable';

function PasswordModal({ onCorrectPassword }) {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'Muzify2024') {
      localStorage.setItem('isAuthenticated', 'true');
      onCorrectPassword();
    } else {
      setError('Incorrect password. Please try again.');
    }
  };

  return (
    <div className="password-modal">
      <div className="modal-content">
        <h2 className='text-xl font-bold my-4'>Enter Password</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            className='border border-gray-300 rounded-md p-2 my-4 w-full'
          />
          <button type="submit" className='bg-blue-500 text-white p-2 rounded-md w-full'>Submit</button>
        </form>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('');

  useEffect(() => {
    const auth = localStorage.getItem('isAuthenticated');
    if (auth === 'true') {
      setIsAuthenticated(true);
    }

    const storedLastUpdated = localStorage.getItem('lastUpdated');
    const now = new Date();

    if (!storedLastUpdated || now - new Date(storedLastUpdated) > 30 * 60 * 1000) {
      // Generate a new time if there's no stored time or it's older than 30 minutes
      const minutesAgo = Math.floor(Math.random() * (15 - 7 + 1)) + 7;
      now.setMinutes(now.getMinutes() - minutesAgo);
      const formattedTime = now.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'GMT'
      });
      const newLastUpdated = `${formattedTime} GMT`;
      localStorage.setItem('lastUpdated', now.toISOString());
      setLastUpdated(newLastUpdated);
    } else {
      // Use the stored time if it's less than 30 minutes old
      const storedDate = new Date(storedLastUpdated);
      const formattedTime = storedDate.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'GMT'
      });
      setLastUpdated(`${formattedTime} GMT`);
    }
  }, []);

  return (
    <div className="App">
      {/* {isAuthenticated ? (
        
      ) : (
        <PasswordModal onCorrectPassword={() => setIsAuthenticated(true)} />
      )} */}
      <div className="container mx-auto px-4 py-8 relative">
        <div className="absolute top-2 right-4 text-sm text-gray-600">
          Updated as of {lastUpdated}
        </div>
        <h1 className="text-3xl font-bold mb-8 text-center">Dashboard</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-bold mb-4">Monthly Users</h2>
            <TotalCumulativeUsersMonthly />
          </div>
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-bold mb-4">Genre Listeners</h2>
            <GenreListener />
          </div>
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-bold mb-4">Weekly Data</h2>
            <WeekOnWeekTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
